.u-offscreen {
	position: absolute;
	top: -100%;
}

.u-inline {
    display: inline-block;
}

.u-float-right {
    float: right;
}

.u-float-left {
    float: left;
    margin-right: 20px;
}

.u-text-left {
    text-align: left;
}
.u-text-center {
    text-align: center;
}
.u-text-right {
    text-align: right;
}

.u-no-list {
    list-style: none;
}

.u-stack + .u-stack {
    margin-right: 5px;
}

.u-cf {
    @extend %cf;
}

.u-pointer {
    cursor: pointer;
}

.u-visible-sm {
    display: none;

    @media only screen and (max-width: $breakpoint-sm) {
        display: block;
    }
}

@media only screen and (max-width: $breakpoint-sm) {
    .u-hidden-sm {
        display: none !important;
    }
}

.u-wrapper {
    margin: auto;
    max-width: $breakpoint-lg;
    position: relative;
}

.u-wrapper-indented {
    margin: auto;
    max-width: $breakpoint-lg - 40;
    position: relative;

    @media only screen and (max-width: $breakpoint-lg) {
        max-width: 100%;
        margin: 0 20px;
    }
    @media only screen and (max-width: $breakpoint-xs) {
        margin: 0 10px;
    }
}

.flexbox .u-justify-blocks {
    display: flex;
    flex-wrap: wrap;
    margin-right: -6px;

    & > li {
        margin: 0 6px 6px 0;
        flex-grow: 1;
    }
}

.no-flexbox .u-justify-blocks > li {
    margin: 0 6px 6px 0;
    float: left;
}
