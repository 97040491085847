@function rem($size) {
	$rem-size: calc($size / 16);
	@return #{$rem-size}rem;
}

#vitalitySic {

  h1 {
    @include fontOpenSansCondensedLight;
    font-size: rem(36);
    margin-bottom: 5px;
    text-transform: uppercase;
    color: $brand-primary;

    @media only screen and (max-width: $breakpoint-sm) {
      font-size: rem(30);
    }
  }

  h2 {
    @include fontOpenSansCondensedLight;
    font-size: rem(44);
    text-transform: uppercase;
    margin-bottom: 20px;

    @media only screen and (max-width: $breakpoint-xs) {
      font-size: rem(25);
    }
  }

  h3 {
    @include fontOpenSansCondensedLight;
    font-size: rem(36);
    text-transform: uppercase;
    margin-bottom: 10px;

    @media only screen and (max-width: $breakpoint-xs) {
      font-size: rem(28);
    }
  }


  h4 {
    @include fontHindRegular;
    font-size: rem(18);
    color: $greyscale2;

    @media only screen and (max-width: $breakpoint-sm) {
      font-size: rem(16);
    }
  }

  a {
    color: $brand-primary;

    &:hover {
      color: $greyscale1;
    }
  }

  ol {
    margin-left: 20px;
    margin-top: 10px;

    li {
      padding-left: 10px;
    }
  }

  .instructions {
    @include fontHindRegular;
    font-size: rem(21);
    margin-bottom: 22px;

    @media only screen and (max-width: $breakpoint-sm) {
      font-size: rem(16);
      margin-bottom: 15px;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;

    &--large {
      width: 30px;
      height: 30px;
    }
  }

  .fa-times {
    color: $red;
    font-size: 2rem;
  }

}
