.key {
	@extend %cf;
	list-style: none;

	li {
		float: left;
		margin-bottom: 10px;
		margin-right: 20px;
		height: 20px;
		line-height: 20px;

		&:first-child {
			height: 21px; // make it bigger so the others indent
		}

		@media only screen and (max-width: $breakpoint-xs) {
			float: none;
			font-size: rem(13);
		}
	}

	.icon {
		margin-right: 5px;
	}
}