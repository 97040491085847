.btn {
	@include fontHindBold;
	border-radius: 4px;
	border: 4px solid;
	padding: 12px 20px;
	margin-top: 10px;
	font-size: rem(18);
	color: $neg;

	@media only screen and (max-width: $breakpoint-md) {
		font-size: rem(15);
	}

	i {
		margin-left: 15px;
		font-size: rem(16);
		border-radius: 50%;
		padding: 6px;
	}

	&--secondary {
		background: $brand-secondary;
		border-color: darken($brand-secondary, 30%);

		i {
			background: darken($brand-secondary, 30%);
		}

		&:hover {
			background: darken($brand-secondary, 10%);
		}
	}
	&--primary {
		background: $brand-primary;
		border-color: darken($brand-primary, 20%);

		i {
			background: darken($brand-primary, 20%);
		}

		&:hover {
			background: darken($brand-primary, 10%);
		}
	}
}

p + .btn {
	margin-top: 20px;

	@media only screen and (max-width: $breakpoint-md) {
		margin-top: 10px;
	}
}

.btn + p {
	margin-top: 20px;
}