// grid

$grid-width: 96%;
$grid-gutter: 4%;

.grid {
  margin: 0 -2%;
}

.grid__row {
  position: relative;
  width: 100%;
}

.grid__row [class^='col'] {
  float: left;
  margin: 0 2%;
}

.grid__row::after {
  content: '';
  display: table;
  clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: $grid-width;
}

.col-1-sm { width: calc($grid-width / 12) - calc($grid-gutter * 11 / 12); }
.col-2-sm { width: calc($grid-width / 6) - calc($grid-gutter * 10 / 12); }
.col-3-sm { width: calc($grid-width / 4) - calc($grid-gutter * 9 / 12); }
.col-4-sm { width: calc($grid-width / 3) - calc($grid-gutter * 8 / 12); }
.col-5-sm { width: calc($grid-width / (12 / 5)) - calc($grid-gutter * 7 / 12); }
.col-6-sm { width: calc($grid-width / 2) - calc($grid-gutter * 6 / 12); }
.col-7-sm { width: calc($grid-width / (12 / 7)) - calc($grid-gutter * 5 / 12); }
.col-8-sm { width: calc($grid-width / (12 / 8)) - calc($grid-gutter * 4 / 12); }
.col-9-sm { width: calc($grid-width / (12 / 9)) - calc($grid-gutter * 3 / 12); }
.col-10-sm { width: calc($grid-width / (12 / 10)) - calc($grid-gutter * 2 / 12); }
.col-11-sm { width: calc($grid-width / (12 / 11)) - calc($grid-gutter * 1 / 12); }
.col-12-sm { width: $grid-width; }

@media only screen and (min-width: $breakpoint-xs) {
  .col-1-md { width: calc($grid-width / 12) - calc($grid-gutter * 11 / 12); }
  .col-2-md { width: calc($grid-width / 6) - calc($grid-gutter * 10 / 12); }
  .col-3-md { width: calc($grid-width / 4) - calc($grid-gutter * 9 / 12); }
  .col-4-md { width: calc($grid-width / 3) - calc($grid-gutter * 8 / 12); }
  .col-5-md { width: calc($grid-width / (12 / 5)) - calc($grid-gutter * 7 / 12); }
  .col-6-md { width: calc($grid-width / 2) - calc($grid-gutter * 6 / 12); }
  .col-7-md { width: calc($grid-width / (12 / 7)) - calc($grid-gutter * 5 / 12); }
  .col-8-md { width: calc($grid-width / (12 / 8)) - calc($grid-gutter * 4 / 12); }
  .col-9-md { width: calc($grid-width / (12 / 9)) - calc($grid-gutter * 3 / 12); }
  .col-10-md { width: calc($grid-width / (12 / 10)) - calc($grid-gutter * 2 / 12); }
  .col-11-md { width: calc($grid-width / (12 / 11)) - calc($grid-gutter * 1 / 12); }
  .col-12-md { width: $grid-width; }
}


@media only screen and (min-width: $breakpoint-md) {
  .col-1 { width: calc($grid-width / 12) - calc($grid-gutter * 11 / 12); }
  .col-2 { width: calc($grid-width / 6) - calc($grid-gutter * 10 / 12); }
  .col-3 { width: calc($grid-width / 4) - calc($grid-gutter * 9 / 12); }
  .col-4 { width: calc($grid-width / 3) - calc($grid-gutter * 8 / 12); }
  .col-5 { width: calc($grid-width / (12 / 5)) - calc($grid-gutter * 7 / 12); }
  .col-6 { width: calc($grid-width / 2) - calc($grid-gutter * 6 / 12); }
  .col-7 { width: calc($grid-width / (12 / 7)) - calc($grid-gutter * 5 / 12); }
  .col-8 { width: calc($grid-width / (12 / 8)) - calc($grid-gutter * 4 / 12); }
  .col-9 { width: calc($grid-width / (12 / 9)) - calc($grid-gutter * 3 / 12); }
  .col-10 { width: calc($grid-width / (12 / 10)) - calc($grid-gutter * 2 / 12); }
  .col-11 { width: calc($grid-width / (12 / 11)) - calc($grid-gutter * 1 / 12); }
  .col-12 { width: $grid-width; }
}
