.box {
	background: $greyscale5;
	padding: 40px 15px;

	@media only screen and (max-width: $breakpoint-md) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.box + .box {
	border-top: 2px solid $greyscale1;
}