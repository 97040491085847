//============================================
// Sprite Definition
//============================================

$i-map: (
  capped-width: 20px,
  capped-height: 20px,

  comprehensive-width: 20px,
  comprehensive-height: 20px,

  download-width: 17px,
  download-height: 23px,

  info-width: 20px,
  info-height: 20px,

  plus-width: 20px,
  plus-height: 20px,

  unique-width: 20px,
  unique-height: 20px,

  vitality-width: 270.13px,
  vitality-height: 107.21px,
);

.i-capped {
  width: 20px;
  height: 20px;
}

.i-comprehensive {
  width: 20px;
  height: 20px;
}

.i-download {
  width: 17px;
  height: 23px;
}

.i-info {
  width: 20px;
  height: 20px;
}

.i-plus {
  width: 20px;
  height: 20px;
}

.i-unique {
  width: 20px;
  height: 20px;
}

.i-vitality {
  width: 270.13px;
  height: 107.21px;
}

