.footer {
	background-color: $greyscale2;
	color: $greyscale3;
	box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
	padding: 20px 0;
	margin-top: 50px;

	@media only screen and (max-width: $breakpoint-sm) {
		margin-top: 30px;
	}

	&__social-container {
		margin-bottom: 20px;
	}

	&__social-link {
		background-color: $greyscale3;
		border-radius: 50%;
		color: $greyscale2;
		display: inline-block;
		font-size: rem(18);
		margin-right: 10px;
		text-align: center;
		height: 1.8em;
		width: 1.8em;

		i {
			margin-top: 0.4em;
		}
	}
}