.toolheader {
	background: $darkblue;
	padding: 45px 22px 35px;
	color: $neg;
	text-align: center;
	border-radius: 20px 20px 0 0;
	margin-top: 50px;

	@media only screen and (max-width: $breakpoint-md) {
		padding-top: 40px;
		margin-top: 35px;
	}
}