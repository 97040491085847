
.table {
	@include fontHindRegular;
	min-width: 950px; // minimum required for content to fit (.payout width * 10)
	width: 100%;
	transition: background 1s;

	&--updated {
		background: lighten($lightblue, 40%);
		transition: none;
	}

	td {
		padding: 12px 0 8px;
		border-bottom: 1px solid $greyscale3;

		&.table__text {
			color: $lightblue;
			padding-right: 4px;
		}

		// all the headers
		&.table__header,
		&.table__subhead,
		&.table__header-provider,
		&.table__header-vitality,
		&.table__subhead-provider,
		&.table__subhead-vitality,
		&.table__divider {
			border-left: 1px solid;
			border-color: $neg;
			color: $neg;
			padding-left: 4px;
			padding-right: 4px;

			a {
				color: $neg;
			}
		}

		// just the leftmost rows
		&.table__text,
		&.table__header,
		&.table__divider,
		&.table__subhead {
			padding-left: 15px;

			@media only screen and (max-width: $breakpoint-sm) {
				padding-left: 8px;
			}
		}

		// just the main headers
		&.table__header,
		&.table__header-provider,
		&.table__header-vitality {
			font-size: rem(18);
			padding-top: 20px;
			padding-bottom: 17px;
		}

		// general headers
		&.table__header,
		&.table__subhead {
			background: $greyscale1;
		}
		&.table__divider {
			font-size: rem(18);
			background: $lightblue;

      &--pink {
        background: $brand-primary;
      }

		}
		&.table__header {
			border-bottom: none;
		}

		// provider headers
		&.table__header-provider,
		&.table__subhead-provider {
			background: $darkblue;
			text-align: center;
		}

		// vitality headers
		&.table__header-vitality,
		&.table__subhead-vitality {
			background: $brand-primary;
			text-align: center;
		}

		&.table__subhead-provider,
		&.table__subhead-vitality {
			.icon {
				margin-top: 5px;
			}
		}
	}

	&__pdf {
		display: inline-block;
		fill: $neg;

		&:hover {
			fill: $brand-secondary;
		}
	}
}

@media only screen and (max-width: $breakpoint-sm) {
	.table-wrapper {
	    overflow-x: scroll;
	}
}
