@import 'generic/normalize';
@import 'generic/reset';

@import 'config/fonts';
@import 'config/colours';
@import 'config/sizes';

@import 'base/sprite';

@import 'common/grid';
@import 'common/placeholders';
@import 'common/utils';
@import 'common/text';
@import 'common/button';
@import 'common/input';
@import 'common/checkbox';

@import 'global/page';
@import 'global/header';
@import 'global/footer';
@import 'global/intro';

@import 'components/toggle';
@import 'components/tooltip';
@import 'components/table';
@import 'components/toolheader';
@import 'components/payout';
@import 'components/box';
@import 'components/advert';
@import 'components/stat';
@import 'components/key';
@import 'components/plans';


[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
