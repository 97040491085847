$barheight: 10px;
$barwidth: 95px; // minimum required for content to fit

.payout {
	position: relative;
	left: calc(50% - (#{$barwidth} / 2));
	width: $barwidth;
	font-size: rem(13);
	line-height: 24px;

	&__none {
		margin-top: -5px;
	}

	&__bar {
		height: $barheight;
		background-color: lighten($brand-primary, 40%);
		margin-bottom: 5px;
	}
	
	&__barfill {
		height: $barheight;
		background: $brand-primary;
	}

	&--provider {
		.payout__bar {
			background: $greyscale4;
		}
		.payout__barfill {
			background: $greyscale3;
		}
	}
}