.advert {
	@extend %cf;
	margin: 80px 0 30px;

	@media only screen and (max-width: $breakpoint-sm) {
		margin: 30px 0;
	}

	&__text {
		width: 50%;
		float: left;
		background: $brand-secondary;
		color: $neg;
		padding: 65px;
		position: relative;

		@media only screen and (max-width: $breakpoint-md) {
			padding: 30px;
		}

		@media only screen and (max-width: $breakpoint-xs) {
			padding: 30px;
			width: 100%;
			float: none;
		}

		&::after {
			content: '';
			position: absolute;
			width: 100%;
		    height: 100%;
		    top: 0;
		    left: 100%;
		    background-image: url('../images/show_client.jpg');
			background-position: left center;
			background-size: cover;
			background-repeat: no-repeat;

			@media only screen and (max-width: $breakpoint-xs) {
				display: none;
			}
		}
	}
}
