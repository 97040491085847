$input-height: 36px;

.input {
    position: relative;
    
    input {
    	@include fontHindRegular;
        border: 1px solid $greyscale4;
        color: $greyscale2;
        padding: 5px;
        width: 100%;
        border-radius: 0;
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 1px; // for the border
        background: $greyscale1;
        color: $neg;
        font-size: rem(23);
        line-height: $input-height !important;
        height: calc(100% - 2px); // minus the border
        width: $input-height;
        text-align: center;
    }
}

.input-label {
    line-height: $input-height;
}