.plans {
  margin-top: 1.5em;
  text-align: left;

  &__instructions {
    font-family: 'Hind', helvetica, sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    margin-bottom: 0.5em;
  }

  &__item {
    display: inline-block;
    margin-right: 0.25em;
  }
}
