.intro {
	padding: 60px 0;
	background: $brand-primary;
	color: $neg;
	text-align: center;
	position: relative;
	box-shadow: inset 0px 4px 13px -1px rgba(0, 0, 0, 0.47);
	margin-bottom: 40px;

	@media only screen and (max-width: $breakpoint-sm) {
		padding: 30px 0 20px;
		margin-bottom: 40px;
	}
}