.toggle {
	list-style: none;

	input[type='checkbox'] {
		display: none;
	}

	label {
		@include fontHindRegular;
		display: block;
		cursor: pointer;
		padding: 10px 1em 7px;
		border: 2px solid $neg;
		color: $neg;
		border-radius: 25px;
		text-align: center;

		&:hover {
			background: $greyscale1;
		}
	}

	&--paused label {
		font-style: italic;
		background: $greyscale2;

		&:hover {
			background: $greyscale2;
		}
	}

	// this should overwrite paused
	&--disabled label {
		opacity: 0.5;
		cursor: not-allowed;

		&:hover {
			background: none;
		}
	}

	// last as most important
	&--selected label {
		background: $neg;
		color: $darkblue;

		&:hover {
			background: $neg;
		}
	}
}