.checkbox {
	position: relative;
	max-width: 300px;
	margin-bottom: 5px;

	input[type='checkbox'] {
		display: none;
	}

	& label {
		display: flex;
		align-items: center;
		min-height: 1.6rem;

		&::before {
			content: '\f0c8';
		    font-family: $font-awesome;
			font-size: 1.4rem;
			display: block;
			margin-right: 5px;
		}
	}

	&--selected label::before {
		content: '\f14a';
	}

}

.radio {
	position: relative;
	max-width: 300px;
	margin-bottom: 5px;

	input[type='radio'] {
		display: none;
	}

	& label {
		display: flex;
		align-items: center;
		min-height: 1.6rem;

		&::before {
			content: '\f111';
		    font-family: $font-awesome;
			font-size: 1.4rem;
			display: block;
			margin-right: 5px;
		}
	}

	&--selected label::before {
		content: '\f058';
	}

}