$arrow-size: 10px;
$box-width: 200px;
$icon-width: 20px;

.tooltip {
	@include fontHindRegular;
	pointer-events: none;
	opacity: 0;
	background-color: $neg;
	color: $greyscale1;
	position: fixed;
	width: $box-width;
	padding: 10px;
	transform: translateY(-50%);
	transition: opacity 0.2s ease-in-out;
	text-align: center;
	box-shadow: 0px 0px 25px 2px $greyscale2;
	text-align: left;
	font-size: rem(13);
	margin-top: 10px; // alignment fix?
	margin-left: $arrow-size + $icon-width;

	ul {
		margin-left: 20px;
    	margin-top: 10px;
	}

	&--open {
		opacity: 1;
		z-index: 1040; // above floating table header
	}

	&::after {
		content: '';
		position: absolute;
		border-top: $arrow-size solid transparent;
		border-bottom: $arrow-size solid transparent;
		border-right: $arrow-size solid $neg;
	    top: calc(50% - #{$arrow-size});
	    left: -$arrow-size;
	}

	&--flipped {
		margin-left: -$box-width - $arrow-size;

		&::after {
			left: auto;
			right: -$arrow-size;
			border-left: $arrow-size solid $neg;
			border-right: none;
		}
	}
}