.stat {
	border-radius: 50%;
	color: $neg;
	margin: 0 auto;
	text-align: center;
	position: relative;
	width: 185px;
	height: 185px;
	padding: 30px 23px 0;

	@media only screen and (max-width: $breakpoint-xs) {
		text-align: left;
		margin-bottom: 10px;
		width: 100%;
		height: auto;
		padding: 0;
	}

	&--primary {
		background: $brand-primary;

		@media only screen and (max-width: $breakpoint-xs) {
			color: $brand-primary;
			background: none;
		}
	}

	&--secondary {
		background: $greyscale2;

		@media only screen and (max-width: $breakpoint-xs) {
			color: $greyscale2;
			background: none;
		}
	}

	&__figure {
		@include fontHindBold;
		font-size: rem(48);
		line-height: 1em;
	}

	&__text,
	&__pretext {
		@include fontHindSemiBold;
		font-size: rem(13);
	}

	&__pretext {
		min-height: 15px;

		@media only screen and (max-width: $breakpoint-xs) {
			min-height: 0;
		}
	}
}

.stat-container {
	@extend %cf;

	&__stat {
		width: 33%;
		float: left;
		padding: 0 60px;
		position: relative;

		@media only screen and (max-width: $breakpoint-md) {
			padding: 0 30px;
		}
		@media only screen and (max-width: $breakpoint-sm) {
			padding: 0 7px;
		}
		@media only screen and (max-width: $breakpoint-xs) {
			width: 100%;
			float: none;
			margin-bottom: 20px;
		}

		&::after {
			content: '';
			position: absolute;
			border-right: 1px solid $greyscale3;
			top: 30px;
			bottom: 30px;
			right: 0;

			@media only screen and (max-width: $breakpoint-xs) {
				display: none;
			}
		}
	}
	&__stat:last-child::after {
		display: none;
	}

	ul {
		margin-left: 20px;
	}
}