$brand-primary: #f41c5e;
$brand-secondary: #5FB6B0;

$neg: #fff;
$greyscale1: #333;
$greyscale2: #666;
$greyscale3: #B3B3B3;
$greyscale4: #E8E8E8;
$greyscale5: #F2F2F2;

$red: #C02235;
$darkblue: #34404C;
$lightblue: #507389;
